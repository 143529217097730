const main_menu = [
  { key: "issues_manage", label: "Zgłoszenia oczekujące" },
  { key: "issues", label: "Zgłoszenia" },
  { key: "workers", label: "Pracownicy" },
  {
    key: "clients",
    label: "Klienci",
  },
  {
    key: "alerts",
    label: "Próby dostępu",
  },
  {
    key: "notes",
    label: "Notatki",
  },
  {
    key: "calendar",
    label: "Kalendarz",
  },
];

export default main_menu;
