import dayjs from "dayjs";
import React, {
  useMemo,
  useContext,
  useState,
} from "react";
import { api } from "helpers/api";
import useGet from "hooks/useGet";

const CalendarContext = React.createContext();

function CalendarProvider({ children }) {
  const [day_calendar_date, setDayCalendarDate] =
    useState(false);
  const [update_count, setUpdateCount] = useState(0);
  const [total_count, setTotalCount] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [calendar_worker_id, setCalendarWorkerId] =
    useState(null);

  const {
    is_loading: is_loading_tasks,
    data: day_tasks = [],
    refetch: refetchDayTasks,
  } = useGet({
    query: ["day_tasks", day_calendar_date],
    path: "/tasks",
    search: {
      per_page: 100,
      date: dayjs(day_calendar_date).format(
        "DD-MM-YYYY HH:mm"
      ),
    },
    options: {
      enabled: !!day_calendar_date,
      refetchOnWindowFocus: false,
    },
  });

  const {
    is_loading: is_loading_day_events,
    data: day_events = [],
    refetch: refetchDayEvents,
  } = useGet({
    query: [
      "day_events",
      day_calendar_date,
      calendar_worker_id,
    ],
    path: "/issues",
    context: "calendar_day",
    search: {
      date: day_calendar_date,
      per_page: 20,
      "sort[column]": "service_date",
      "sort[direction]": "desc",
      ...(calendar_worker_id
        ? { "q[worker_id_eq]": calendar_worker_id }
        : {}),
      "q[service_date_gteq]": dayjs(day_calendar_date)
        .startOf("date")
        .format("DD-MM-YYYY HH:mm"),
      "q[service_date_lteq]": dayjs(day_calendar_date)
        .endOf("date")
        .format("DD-MM-YYYY HH:mm"),
    },
    options: {
      enabled: !!day_calendar_date,
      refetchOnWindowFocus: false,
    },
  });

  const { data: day_holidays = [] } = useGet({
    query: ["day_holidays", day_calendar_date],
    path: "/holidays",
    search: {
      date_start: dayjs(day_calendar_date)
        .startOf("date")
        .format("DD-MM-YYYY HH:mm"),

      date_end: dayjs(day_calendar_date)
        .endOf("date")
        .format("DD-MM-YYYY HH:mm"),
    },
    options: {
      enabled: !!day_calendar_date,
      refetchOnWindowFocus: false,
    },
  });

  const is_open_modal = !!day_calendar_date;

  const getCalendarIssues = async ({ start, end }) => {
    const start_date = dayjs(start).format();
    const end_date = dayjs(end).format();
    try {
      const { data } = await api.get(
        `/issues/calendar?start_date=${start_date}&end_date=${end_date}&worker_id=${calendar_worker_id}`
      );
      setTotalCount(data.total);
      setHolidays(data.holidays);
      return data?.issues?.map(({ date, count }) => ({
        event_id: date,
        start: dayjs(date).toDate(),
        end: dayjs(date).toDate(),
        title: `Ilość zgłoszeń: ${count}`,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const searchIssues = async (q) => {
    try {
      const { data } = await api.get(
        `/issues?per_page=10&context=list&q[service_date_null]=true&q[identifier_or_client_phone_or_client_first_name_or_client_last_name_or_client_city_or_client_post_code_or_client_street_matches]=%25${q}%25`
      );
      return data;
    } catch (error) {
      throw error;
    }
  };

  const value = useMemo(() => {
    return {
      is_open_modal,
      day_calendar_date,
      update_count,
      is_loading_tasks,
      is_loading_day_events,
      day_tasks,
      day_events,
      total_count,
      holidays,
      day_holidays,
      calendar_worker_id,
      setDayCalendarDate,
      getCalendarIssues,
      searchIssues,
      setUpdateCount,
      refetchDayTasks,
      refetchDayEvents,
      setCalendarWorkerId,
    };
    // eslint-disable-next-line
  }, [
    is_open_modal,
    update_count,
    day_calendar_date,
    is_loading_tasks,
    is_loading_day_events,
    day_tasks,
    day_events,
    day_holidays,
    calendar_worker_id,
  ]);

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
}

const useCalendar = () => useContext(CalendarContext);
export { useCalendar };
export default CalendarProvider;
