import React from "react";
import { useQueryClient } from "@tanstack/react-query";

import MainLayout from "components/MainLayout";
import MainList from "components/MainList";

import columns from "./columns";
import CreateIssueModal from "components/create_modals/CreateIssueModal";
import { useWorkers } from "context/providers/workers";

const IssuesList = () => {
  const queryClient = useQueryClient();
  const { workers_list_options } = useWorkers();

  const onSuccess = () =>
    queryClient.refetchQueries({
      queryKey: ["issues"],
    });

  const columns_data = columns(workers_list_options);

  return (
    <MainLayout
      breadcrumbs={[{ name: "Zgłoszenia" }]}
      buttons={<CreateIssueModal onSuccess={onSuccess} />}
    >
      <div className="issues-table">
        <MainList
          columns={columns_data}
          query_name="issues"
          query_path="/issues"
          table_path="/issues"
          query_context="index"
        />
      </div>
    </MainLayout>
  );
};

export default IssuesList;
