import { useState } from "react";
import { Button, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { useAuth } from "context/providers/auth";
import CreateUserPinModal from "./components/CreateUserPinModal";

const UserPanel = () => {
  const { state } = useAuth();
  const [is_open, setOpenCreateModal] = useState(false);

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              className: "username",
              key: "1",
              label: state?.user?.full_name,
              disabled: true,
            },
            {
              key: "2",
              label: "Zmień pin",
              onClick: () => setOpenCreateModal(true),
            },
          ],
        }}
        placement="bottomRight"
        arrow
      >
        <Button
          type="primary"
          htmlType="button"
          icon={<UserOutlined />}
        />
      </Dropdown>
      <CreateUserPinModal
        is_open={is_open}
        handleClose={() => setOpenCreateModal(false)}
      />
    </>
  );
};

export default UserPanel;
