import React from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
} from "antd";

import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import PageFailure from "components/PageFailure";
import { MaskedInput } from "antd-mask-input";
import Logs from "components/Logs";
import useFormErrors from "hooks/useFormErrors";
import {
  building_number_regexp,
  name_regexp,
  nip_regexp,
  street_regexp,
} from "helpers/regexp";
import ModalDelete from "components/ModalDelete";

const ClientUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const [form] = Form.useForm();

  const path = `/clients/${id}`;

  const { is_loading, is_error, data, refetch } = useGet({
    path,
    query: ["clients", id],
  });

  const { is_saving, update } = useUpdate({
    path,
    form,
    onSuccess: refetch,
  });

  const { is_deleting, _delete } = useDelete({
    path,
    onSuccess: () => navigate("/clients"),
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono klienta"
        button_text="Wróć do listy"
        path="/clients"
      />
    );
  }
  return (
    <MainLayout
      back_href="/clients"
      breadcrumbs={[
        { name: "Klienci", path: "/clients" },
        { name: data?.id || "Trwa pobieranie..." },
      ]}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24} lg={12} xl={8}>
          <Card title="Edytuj klienta" loading={is_loading}>
            <Form
              form={form}
              layout="vertical"
              onFieldsChange={onFieldsChange}
              initialValues={{
                first_name: data?.first_name,
                last_name: data?.last_name,
                phone: data?.phone,
                email: data?.email,
                street: data?.street,
                building_number: data?.building_number,
                apartment_number: data?.apartment_number,
                city: data?.city,
                post_code: data?.post_code,
                nip: data?.nip,
              }}
              onFinish={(values) => update(values)}
            >
              <Form.Item
                label="Imię"
                name="first_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Imię jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input placeholder="Imię" maxLength={60} />
              </Form.Item>
              <Form.Item
                label="Nazwisko"
                name="last_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nazwisko jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nazwisko"
                  maxLength={60}
                />
              </Form.Item>
              <Form.Item
                label="Telefon"
                name="phone"
                required
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput mask="000000000" />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                hasFeedback
                rules={[
                  {
                    type: "email",
                    message: "Niepoprawny e-mail",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  maxLength={60}
                />
              </Form.Item>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label="Ulica"
                    name="street"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Ulica jest wymagane!",
                      },

                      {
                        pattern: street_regexp,
                        message:
                          "Pole zawiera niedozwolone znaki!!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Ulica"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    label="Nr bud."
                    name="building_number"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message:
                          "Numer budynku jest wymagany!",
                      },
                      {
                        pattern: building_number_regexp,
                        message:
                          "Pole zawiera niedozwolone znaki!!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nr bud."
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item
                    label="Nr miesz."
                    name="apartment_number"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message:
                          "Numer mieszkania jest wymagany, w przypadku domu jednorodzinnego proszę wpisać numer budynku",
                      },
                      {
                        pattern: building_number_regexp,
                        message:
                          "Pole zawiera niedozwolone znaki!!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nr miesz."
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label="Miasto"
                    name="city"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Miasto jest wymagane!",
                      },
                      {
                        pattern: name_regexp,
                        message:
                          "Pole zawiera niedozwolone znaki!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Miasto"
                      maxLength={60}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Kod pocztowy"
                    name="post_code"
                    required
                    hasFeedback
                    rules={[
                      () => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.reject(
                              new Error(
                                "Kod pocztowy wymagany!"
                              )
                            );
                          }
                          if (value.includes("_")) {
                            return Promise.reject(
                              new Error(
                                "Kod pocztowy niepoprawny!"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <MaskedInput mask="00-000" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label="NIP"
                    name="nip"
                    hasFeedback
                    rules={[
                      {
                        pattern: nip_regexp,
                        message: "Niepoprawny NIP",
                      },
                    ]}
                  >
                    <MaskedInput mask="0000000000" />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Space>
                  <ModalDelete
                    loading={is_deleting}
                    text="klienta"
                    handleDelete={_delete}
                  />
                  <Button
                    disabled={pristine || has_error}
                    loading={
                      is_deleting || is_loading || is_saving
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Zapisz
                  </Button>
                </Space>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xs={24} md={24} lg={12} xl={16}>
          <Card
            title="Logi klienta"
            loading={is_loading}
            bodyStyle={{
              maxHeight: 595,
              overflowY: "auto",
            }}
          >
            <Logs data={data?.logs || []} />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default ClientUpdate;
