import React, { useState } from "react";
import { notification, Button, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import {
  useQueryParams,
  JsonParam,
  NumberParam,
} from "use-query-params";
import { useQuery } from "@tanstack/react-query";
import { api, createQuery } from "helpers/api";

import Filters from "./components/Filters";

const MainList = ({
  columns,
  query_name = "",
  query_path = "",
  table_path = "",
  query_context,
}) => {
  const [paginate, setPaginate] = useQueryParams({
    page: NumberParam,
    per_page: NumberParam,
    sort: JsonParam,
  });

  const [query, setQuery] = useState({});
  const [search_values, setSearchValues] = useState({});

  const { isLoading: is_loading, data } = useQuery(
    [query_name, paginate, query],
    () =>
      api.get(
        `${query_path}?${createQuery({
          context: query_context,
          paginate,
          query,
        })}`
      ),
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) =>
        notification.error({
          message:
            err?.response?.data?.message || "Wystąpił błąd",
        }),
    }
  );

  const onTableDataChange = (
    pagination,
    _,
    sort,
    { action }
  ) => {
    if (action === "paginate") {
      setPaginate({
        ...paginate,
        page: pagination.current,
        per_page: pagination.pageSize,
      });
      return;
    }
  };

  const onSearch = ({ name, value, search_method }) => [
    setQuery({
      ...query,
      [name]: { value, search_method },
    }),
    setPaginate({
      ...paginate,
      page: undefined,
      
    }),
  ];

  return (
    <>
      <Filters
        columns={columns}
        search_values={search_values}
        onSearch={onSearch}
        setSearchValues={setSearchValues}
        setQuery={setQuery}
        paginate={paginate}
        setPaginate={setPaginate}
        total_count={data?.meta?.total_count}
      />
      <Table
        loading={is_loading}
        columns={[
          ...columns,
          table_path
            ? {
                title: "",
                width: 50,
                render: (_, { id }) => (
                  <Link to={`${table_path}/${id}`}>
                    <Button icon={<EditOutlined />} />
                  </Link>
                ),
              }
            : null,
        ].filter(Boolean)}
        pagination={{
          current: data?.meta?.current_page || 1,
          pageSize: data?.meta?.per_page || 10,
          total: data?.meta?.total_count || 0,
          pageSizeOptions: ["10", "15", "30", "50"],
        }}
        dataSource={data?.data?.map((item) => ({
          ...item,
          key: item.id,
        }))}
        onChange={onTableDataChange}
      />
    </>
  );
};

export default MainList;
