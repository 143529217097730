import React, { useState } from "react";
import {
  Modal,
  Input,
  Typography,
  ColorPicker,
} from "antd";

import useUpdate from "hooks/useUpdate";

const EditTask = ({
  id,
  refetch,
  is_important,
  initial_values,
  handleClose,
}) => {
  const [color, setColor] = useState(initial_values.color);
  const [content, setContent] = useState(
    initial_values.content
  );

  const { is_saving, updateAsync } = useUpdate({
    path: `/tasks/${id}`,
    onSuccess: refetch,
  });

  const is_dirty =
    initial_values.content !== content ||
    initial_values.color !== color;

  const handleOk = async () => {
    try {
      await updateAsync({
        content,
        color,
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      maskClosable={false}
      width={700}
      title={`Edytuj ${
        is_important ? "priorytet" : "zadanie poboczne"
      }`}
      open={true}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText="Zapisz"
      okButtonProps={{
        disabled: !content || is_saving || !is_dirty,
        loading: is_saving,
      }}
    >
      <Input.TextArea
        value={content}
        style={{ marginBottom: 20 }}
        onChange={({ target: { value } }) =>
          setContent(value)
        }
        rows={7}
        maxLength={400}
        showCount={true}
      />
      <Typography style={{ marginBottom: 5 }}>
        <Typography.Text>Wybierz kolor</Typography.Text>
      </Typography>
      <ColorPicker
        value={color}
        onChangeComplete={(value) =>
          setColor(value.toHexString())
        }
      />
    </Modal>
  );
};

export default EditTask;
