import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
} from "antd";

import useCreate from "hooks/useCreate";
import useFormErrors from "hooks/useFormErrors";
import { useQueryClient } from "@tanstack/react-query";
import note_kinds from "helpers/options/note_kinds";
import { useWorkers } from "context/providers/workers";
import InputDate from "components/InputDate";
import { special_characters_regexp } from "helpers/regexp";

const CreateNoteModal = () => {
  const queryClient = useQueryClient();
  const { user_list_options } = useWorkers();
  const [is_open_create_modal, setOpenCreateModal] =
    useState(false);

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const { is_saving, create } = useCreate({
    path: "/notes",
    form,
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["notes"],
      }),
  });

  const handleOk = () => form.submit();
  const handleClose = () => setOpenCreateModal(false);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => setOpenCreateModal(true)}
      >
        Dodaj notatkę
      </Button>
      <Modal
        maskClosable={false}
        title="Dodaj notatkę"
        open={is_open_create_modal}
        onOk={handleOk}
        onCancel={handleClose}
        cancelText="Anuluj"
        okText="Dodaj"
        okButtonProps={{
          disabled: pristine || has_error,
          loading: is_saving,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          initialValues={{
            content: "",
            kind: "note",
            date_start: "",
            date_end: "",
          }}
          onFinish={async (values) => {
            try {
              await create(values);
              form.resetFields();
              handleClose();
            } catch (error) {
              throw error;
            }
          }}
        >
          {({ kind }) => (
            <>
              <Form.Item
                label="Rodzaj"
                name="kind"
                hasFeedback
              >
                <Select
                  placeholder="Rodzaj"
                  options={note_kinds}
                  defaultValue={note_kinds[0]}
                />
              </Form.Item>
              <Form.Item
                label="Pracownik"
                name="user_id"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Pracownik jest wymagany!",
                  },
                ]}
              >
                <Select
                  placeholder="Pracownik"
                  options={user_list_options}
                />
              </Form.Item>

              {kind === "holiday" ? (
                <Row gutter={[12, 12]}>
                  <Col xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      label="Data rozpoczęcia"
                      name="date_start"
                      rules={[
                        {
                          required: true,
                          message:
                            "Data rozpoczęcia jest wymagana!",
                        },
                      ]}
                    >
                      <InputDate />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={12} xl={12}>
                    <Form.Item
                      label="Data zakończenia"
                      name="date_end"
                      rules={[
                        {
                          required: true,
                          message:
                            "Data zakończenia jest wymagana!",
                        },
                        {
                          validator: (
                            _,
                            value,
                            callback
                          ) => {
                            const date_start =
                              form.getFieldValue(
                                "date_start"
                              );
                            const date_end = value;
                            date_start &&
                            date_end &&
                            date_end - date_start < 0
                              ? callback(
                                  "Data zakończenia nie może być wcześniej niż data rozpoczecia!"
                                )
                              : callback();
                          },
                        },
                      ]}
                    >
                      <InputDate
                        disabledDate={(current) =>
                          current &&
                          current <
                            form.getFieldValue("date_start")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Form.Item
                  label="Treść"
                  name="content"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Treść jest wymagana!",
                    },
                    {
                      pattern: special_characters_regexp,
                      message:
                        "Pole zawiera niedozwolone znaki!!",
                    },
                  ]}
                >
                  <Input.TextArea
                    style={{ marginBottom: 10 }}
                    placeholder="Treść"
                    maxLength={400}
                    showCount={true}
                    rows={7}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default CreateNoteModal;
