import { useRef, useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Modal,
  Row,
  Col,
  Space,
  Typography,
  Alert,
} from "antd";
import dayjs from "dayjs";
import { Scheduler } from "@aldabil/react-scheduler";
import { pl } from "date-fns/locale";
import { useIssueCalendar } from "../../issue_calendar_context";
import calendar_translations from "helpers/calendar_translations";

import EllipsisText from "components/EllipsisText";

import getIssueColorByStatus from "helpers/getIssueColorByStatus";
import CustomEditor from "./components/CustomEditor";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Tasks from "components/Tasks";
import formatPhone from "helpers/phone_formatter";

const IssueDayCalendar = () => {
  const {
    issue_id,
    issue_data,
    date,
    refetch,
    setDate,
    is_open_day_calendar,
    setOpenCalendar,
    day_holidays,
    day_events,
    day_tasks = [],
    refetchDayTasks,
  } = useIssueCalendar();
  var [time, setTime] = useState(new Date());

  const scheduler_ref = useRef();

  const handleClose = () => {
    setDate(null);
  };

  const handleBack = () => {
    setDate(null);
    setOpenCalendar(true);
  };

  const handleConfirm = async () => {
    try {
      await refetch();
      setDate(null);
      setOpenCalendar(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    var timer = setInterval(
      () => setTime(new Date()),
      1000
    );
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const events = day_events.map(
    ({
      id,
      identifier,
      client_name,
      client_address,
      client_phone,
      status,
      service_date,
      service_date_delay,
      worker,
    }) => ({
      event_id: id,
      service_date_delay: service_date_delay,
      color: getIssueColorByStatus(status),
      client_name,
      client_address,
      client_phone,
      worker,
      identifier,
      service_date,
      textColor: status === "in_progress" ? "#000" : "#fff",
      title: (
        <>
          {worker?.id && (
            <>
              <Space style={{ marginBottom: 10 }}>
                <Avatar
                  style={{
                    backgroundColor: worker?.color,
                  }}
                >
                  {worker.initials}
                </Avatar>
                <Typography.Text
                  delete={worker?.is_deleted}
                >
                  <EllipsisText
                    text={worker.full_name}
                    limit={30}
                  />
                </Typography.Text>
              </Space>
              <br />
            </>
          )}
          <Typography.Text strong>
            {client_address}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            {dayjs(service_date).format("HH:mm")} -{" "}
            {dayjs(service_date)
              .add(1, "hour")
              .format("HH:mm")}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            {formatPhone(client_phone)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            <Typography.Link
              target="_blank"
              href={`/issues/${id}`}
              style={{
                color: "#000",
                textDecoration: "underline",
                lineHeight: "20px",
              }}
            >
              Przejdź do edycji
            </Typography.Link>
          </Typography.Text>
        </>
      ),
      start: dayjs(service_date).toDate(),
      end: dayjs(service_date).add(1, "hour").toDate(),
    })
  );

  return (
    <Modal
      className="day-calendar-modal"
      destroyOnClose
      centered
      width={1300}
      size="large"
      maskClosable={false}
      title={
        <Button
          icon={<ArrowLeftOutlined />}
          type="primary"
          htmlType="button"
          onClick={handleBack}
        >
          Wróć
        </Button>
      }
      open={is_open_day_calendar}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText={null}
      footer={null}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `:root {
            --time-value: "${time.toLocaleTimeString()}";
            --day-value: "${dayjs(date).format("dddd")}";
          }`,
        }}
      />
      <Row gutter={24}>
        <Col lg={12} md={24} xs={24}>
          <Scheduler
            ref={scheduler_ref}
            agenda={false}
            selectedDate={date}
            onSelectedDateChange={(date) => setDate(date)}
            locale={pl}
            view="day"
            deletable={false}
            draggable={false}
            hourFormat={24}
            disableViewNavigator={true}
            events={events}
            onEventEdit={() => null}
            onEventClick={() => null}
            translations={calendar_translations}
            eventRenderer={({ event, ...props }) => (
              <div
                {...props}
                draggable={false}
                className="calendar-panel"
                style={{
                  background: event.color,
                }}
              >
                <Typography.Text strong>
                  {event.client_address}
                </Typography.Text>
              </div>
            )}
            customEditor={(scheduler) => (
              <CustomEditor
                issue_id={issue_id}
                issue_identifier={issue_data?.identifier}
                handleConfirm={handleConfirm}
                scheduler={scheduler}
              />
            )}
            day={{
              startHour: 5,
              endHour: 25,
              step: 60,
            }}
          />
        </Col>
        <Col
          lg={12}
          md={24}
          xs={24}
          style={{ marginTop: 55 }}
        >
          <Alert
            style={{ marginBottom: 10 }}
            message={
              <>
                <p className="m-0">
                  Ulica:{" "}
                  <strong>
                    {issue_data?.client_street}{" "}
                    {issue_data?.client_building_number}/
                    {issue_data?.client_apartment_number}
                  </strong>
                </p>
                <p className="m-0">
                  Miejscowość:{" "}
                  <strong>{issue_data?.client_city}</strong>
                </p>
                <p className="m-0">
                  Data utworzenia zgłoszenia:{" "}
                  <strong>
                    {" "}
                    {issue_data?.created_at
                      ? dayjs(issue_data.created_at).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : ""}
                  </strong>
                </p>
              </>
            }
          />
          {day_holidays?.map(({ date, name }, index) => (
            <Alert
              style={{ marginBottom: 10 }}
              key={index}
              type="error"
              message={`${date} - ${name}`}
            />
          ))}
          <Tasks
            is_important={true}
            date={dayjs(date).format("YYYY-MM-DD")}
            data={day_tasks}
            refetch={refetchDayTasks}
          />
          <Tasks
            is_important={false}
            date={dayjs(date).format("YYYY-MM-DD")}
            data={day_tasks}
            refetch={refetchDayTasks}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default IssueDayCalendar;
