import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import AlertsList from "views/alerts/List";

import ClientsList from "views/clients/List";
import ClientEdit from "views/clients/Edit";

import DevicesList from "views/devices/List";
import DeviceEdit from "views/devices/Edit";

import IssuesList from "views/issues/List";
import IssueEdit from "views/issues/Edit";
import IssueManagement from "views/issues/Management";

import NotesList from "views/notes/List";
import NoteEdit from "views/notes/Edit";

import NotFound from "views/404";

import WorkerInvitationList from "views/worker_invitations/List";
import WorkerInvitationEdit from "views/worker_invitations/Edit";

import WorkersList from "views/workers/List";
import WorkerEdit from "views/workers/Edit";

import Calendar from "views/calendar/Show";

const Router = () => (
  <Routes>
    <Route
      exact
      path="/"
      element={<Navigate to="/issues_manage" />}
    />
    <Route exact path="/alerts" element={<AlertsList />} />
    <Route
      exact
      path="/clients"
      element={<ClientsList />}
    />
    <Route
      exact
      path="/clients/:id"
      element={<ClientEdit />}
    />
    <Route
      exact
      path="/devices"
      element={<DevicesList />}
    />
    <Route
      exact
      path="/devices/:id"
      element={<DeviceEdit />}
    />
    <Route
      exact
      path="/issues_manage"
      element={<IssueManagement />}
    />
    <Route
      exact
      path="/issues/:id"
      element={<IssueEdit />}
    />
    <Route exact path="/issues" element={<IssuesList />} />

    <Route
      exact
      path="/worker_invitations"
      element={<WorkerInvitationList />}
    />
    <Route
      exact
      path="/worker_invitations/:id"
      element={<WorkerInvitationEdit />}
    />
    <Route exact path="/notes" element={<NotesList />} />
    <Route exact path="/notes/:id" element={<NoteEdit />} />
    <Route
      exact
      path="/workers"
      element={<WorkersList />}
    />
    <Route
      exact
      path="/workers/:id"
      element={<WorkerEdit />}
    />
        <Route
      exact
      path="/calendar"
      element={<Calendar />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default Router;
