import { useState } from "react";
import {
  Badge,
  Button,
  Dropdown,
  List,
  Modal,
  Tag,
} from "antd";
import useDelete from "hooks/useDelete";
import EllipsisText from "components/EllipsisText";
import EditTaskModal from "../EditTaskModal";
import {
  CheckOutlined,
  SettingOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import useCreate from "hooks/useCreate";
import MoveTaskModal from "../MoveTaskModal";
import dayjs from "dayjs";

const Item = ({
  id,
  content,
  color,
  refetch,
  lp,
  is_completed,
  date,
  is_important,
  moved_from_date,
}) => {
  const [is_open_edit_modal, setOpenEditModal] =
    useState(false);
  const [is_open_delete_modal, setOpenDeleteModal] =
    useState(false);
  const [is_open_move_modal, setOpenMoveModal] =
    useState(false);
  const [is_open_complete_modal, setOpenCompleteModal] =
    useState(false);

  const { _delete } = useDelete({
    path: `/tasks/${id}`,
    onSuccess: () => {
      setOpenDeleteModal(false);
      refetch()
    },
  });

  const { create: toggleComplete } = useCreate({
    path: `/tasks/${id}/toggle_completed`,
    success_message: "Zaktualizowano pomyślnie.",
    onSuccess: () => {
      setOpenCompleteModal(false);
      refetch();
    },
  });

  return (
    <List.Item
      className={is_completed ? "line-through" : ""}
      actions={[
        <Button
          onClick={setOpenCompleteModal}
          type="primary"
          ghost
          icon={
            is_completed ? (
              <UndoOutlined />
            ) : (
              <CheckOutlined />
            )
          }
        />,
        <Dropdown
          overlayStyle={{ minWidth: 150 }}
          menu={{
            items: [
              {
                key: "1",
                onClick: setOpenEditModal,
                label: "Edytuj",
              },
              {
                key: "2",
                onClick: setOpenDeleteModal,
                label: "Usuń",
              },
              {
                key: "3",
                onClick: setOpenMoveModal,
                label: "Przenieś",
              },
            ],
          }}
          placement="bottomRight"
          arrow
        >
          <Button
            className="btn-dropdown"
            htmlType="button"
            icon={<SettingOutlined />}
            onClick={(e) => e.stopPropagation()}
          />
        </Dropdown>,
      ]}
    >
      <Modal
        title="Potwierdzenie"
        open={is_open_delete_modal}
        onOk={_delete}
        onCancel={() => setOpenDeleteModal(false)}
        cancelText="Anuluj"
        okText="Potwierdzam"
        okType="danger"
      >
        Czy na pewno chcesz usunąć?
      </Modal>
      <Modal
        title="Potwierdzenie"
        open={is_open_complete_modal}
        onOk={() => toggleComplete()}
        onCancel={() => setOpenCompleteModal(false)}
        cancelText="Anuluj"
        okText="Potwierdzam"
      >
        Czy na pewno chcesz{" "}
        {is_completed
          ? "cofnąć"
          : "oznaczyć jako zakończony"}
        ?
      </Modal>
      <List.Item.Meta
        avatar={
          <Badge count={lp} color={color || "#000000"} />
        }
        title={
          <>
            {moved_from_date && (
              <>
                <Tag bordered={false} color="magenta">
                  Przeniesione z datą{" "}
                  {dayjs(moved_from_date).format(
                    "DD.MM.YYYY"
                  )}
                </Tag>
                <br />
              </>
            )}
            <EllipsisText text={content} limit={400} />
          </>
        }
      />
      {is_open_edit_modal && (
        <EditTaskModal
          id={id}
          handleClose={() => setOpenEditModal(false)}
          initial_values={{
            content,
            color: color || "#000000",
          }}
          refetch={refetch}
        />
      )}
      {is_open_move_modal && (
        <MoveTaskModal
          id={id}
          is_important={is_important}
          date={date}
          handleClose={() => setOpenMoveModal(false)}
          refetch={refetch}
        />
      )}
    </List.Item>
  );
};

export default Item;
