import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Divider,
  Row,
  Col,
  Select,
  Switch,
} from "antd";

import useCreate from "hooks/useCreate";
import useFormErrors from "hooks/useFormErrors";
import {
  building_number_regexp,
  first_name_last_name_regexp,
  name_regexp,
  nip_regexp,
  special_characters_regexp,
  street_regexp,
} from "helpers/regexp";
import { api } from "helpers/api";
import { MaskedInput } from "antd-mask-input";
import issue_statuses from "helpers/options/issue_statuses";
import issue_kinds from "helpers/options/issue_kinds";
import service_date_delays from "helpers/options/service_date_delays";

import InputDate from "components/InputDate";
import range from "helpers/range";

const disabledDateTime = () => ({
  disabledMinutes: () => [
    ...range(1, 15),
    ...range(16, 30),
    ...range(31, 45),
    ...range(46, 60),
  ],
});

const CreateIssueModal = ({ onSuccess }) => {
  const [is_open_create_modal, setOpenCreateModal] =
    useState(false);

  const [form] = Form.useForm();

  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const { is_saving, create } = useCreate({
    path: "/issues",
    form,
    onSuccess,
  });

  const handleOk = () => form.submit();
  const handleClose = () => setOpenCreateModal(false);
  const getClientData = async () => {
    try {
      const client_phone =
        form.getFieldValue("client_phone");
      const { data } = await api.get(
        `/clients/find_by_phone?phone=${client_phone}`
      );
      form.setFieldValue(
        "client_first_name",
        data.first_name
      );
      form.setFieldValue(
        "client_last_name",
        data.last_name
      );
      form.setFieldValue("client_street", data.street);
      form.setFieldValue(
        "client_building_number",
        data.building_number
      );
      form.setFieldValue("client_city", data.city);
      form.setFieldValue(
        "client_post_code",
        data.post_code
      );
    } catch (error) {
      notification.error({
        message: "Nie znaleziono klienta.",
      });
    }
  };

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => setOpenCreateModal(true)}
      >
        Dodaj zgłoszenie
      </Button>
      <Modal
        maskClosable={false}
        title="Dodaj zgłoszenie"
        open={is_open_create_modal}
        onOk={handleOk}
        onCancel={handleClose}
        cancelText="Anuluj"
        okText="Dodaj"
        okButtonProps={{
          disabled: has_error || pristine,
          loading: is_saving,
        }}
      >
        <Form
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
          initialValues={{
            created_by: "user",
            status: "new",
            kind: "antenna",
            service_date: null,
            service_date_delay: 0,
            urgent: false,
            content: "",
            client_first_name: "",
            client_last_name: "",
            client_street: "",
            client_building_number: "",
            client_city: "",
            client_post_code: "",
            client_phone: "",
            client_email: "",
          }}
          onFinish={async (values) => {
            try {
              await create(values);
              form.resetFields();
              handleClose();
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <Divider orientation="center">
            Informacje o zgłoszeniu
          </Divider>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Status"
                name="status"
                hasFeedback
              >
                <Select
                  placeholder="Status"
                  options={issue_statuses}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Rodzaj"
                name="kind"
                hasFeedback
              >
                <Select
                  placeholder="Rodzaj"
                  options={issue_kinds}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Data umówienia"
                name="service_date"
              >
                <InputDate
                  format="DD-MM-YYYY HH:mm"
                  showTime={{ format: "HH:mm" }}
                  disabledTime={disabledDateTime}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Data umówienia (opóźnienie)"
                name="service_date_delay"
              >
                <Select
                  placeholder="Data umówienia (opóźnienie)"
                  options={service_date_delays}
                  defaultValue={service_date_delays[0]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Pilne"
                valuePropName="checked"
                name="urgent"
              >
                <Switch
                  checkedChildren="Tak"
                  unCheckedChildren="Nie"
                  defaultChecked={false}
                  onChange={(value) =>
                    form.setFieldValue("urgent", value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Opis zgłoszenia"
            name="content"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Opis zgłoszenia jest wymagany!",
              },
              {
                pattern: special_characters_regexp,
                message:
                  "Pole zawiera niedozwolone znaki!!",
              },
            ]}
          >
            <Input.TextArea
              placeholder="Opis zgłoszenia"
              maxLength={400}
              showCount={true}
              resize={false}
              rows={7}
            />
          </Form.Item>
          <Divider orientation="center">
            Informacje o kliencie
          </Divider>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Imię"
                name="client_first_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Imię jest wymagane!",
                  },
                  {
                    pattern: first_name_last_name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input placeholder="Imię" maxLength={128} />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Nazwisko"
                name="client_last_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nazwisko jest wymagane!",
                  },
                  {
                    pattern: first_name_last_name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nazwisko"
                  maxLength={128}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="Telefon"
                name="client_phone"
                required
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput
                  mask="000000000"
                  suffix={
                    <Button
                      htmlType="button"
                      onClick={getClientData}
                      disabled={
                        form.getFieldsError([
                          "client_phone",
                        ])?.[0]?.errors?.length > 0
                      }
                      size="small"
                      type="primary"
                    >
                      Pobierz
                    </Button>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={12} xl={12}>
              <Form.Item
                label="E-mail"
                name="client_email"
                hasFeedback
                rules={[
                  {
                    type: "email",
                    message: "Niepoprawny e-mail",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  maxLength={128}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Ulica"
                name="client_street"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Ulica jest wymagane!",
                  },
                  {
                    pattern: street_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Ulica"
                  maxLength={128}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Nr bud."
                name="client_building_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Numer budynku jest wymagany!",
                  },
                  {
                    pattern: building_number_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nr bud."
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item
                label="Nr miesz."
                name="client_apartment_number"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message:
                      "Numer mieszkania jest wymagany, w przypadku domu jednorodzinnego proszę wpisać numer budynku",
                  },
                  {
                    pattern: building_number_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Nr miesz."
                  maxLength={10}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="Miasto"
                name="client_city"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Miasto jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedozwolone znaki!!",
                  },
                ]}
              >
                <Input
                  placeholder="Miasto"
                  maxLength={128}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Kod pocztowy"
                name="client_post_code"
                required
                hasFeedback
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Kod pocztowy wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Kod pocztowy niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput mask="00-000" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item
                label="NIP"
                name="client_nip"
                hasFeedback
                rules={[
                  {
                    pattern: nip_regexp,
                    message: "Niepoprawny NIP",
                  },
                ]}
              >
                <MaskedInput mask="0000000000" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateIssueModal;
