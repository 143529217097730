import React, { useMemo, useRef, useState } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  Input,
  Button,
  Space,
  Switch,
  ColorPicker,
  Avatar,
  Tour,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "components/MainLayout";

import useGet from "hooks/useGet";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import PageFailure from "components/PageFailure";
import { MaskedInput } from "antd-mask-input";
import Logs from "components/Logs";
import useFormErrors from "hooks/useFormErrors";
import { name_regexp } from "helpers/regexp";
import ModalDelete from "components/ModalDelete";
import WorkerDevices from "./components/WorkerDevices";
import WorkerPasswordResets from "./components/WorkerPasswordResets";

const WorkerUpdate = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const [open_tour, setOpenTour] = useState(false);

  const steps = [
    {
      title: "Krok 1 - Aktywuj pracownika",
      description:
        "Kliknij, aby oznaczyć pracownika jako aktywny.",
      target: () => ref1.current,
      nextButtonProps: { children: "Dalej" },
    },
    {
      title: "Krok 2 - Zapisz zmianę",
      description:
        "Kliknij, aby zapisać zmiany w pracowniku.",
      target: () => ref2.current,
      prevButtonProps: { children: "Wróć" },
      nextButtonProps: { children: "Dalej" },
    },
    {
      title: "Krok 3 - Odblokuj urządzenie",
      description:
        "Kliknij, aby oznaczyć urządzenie jako aktywne.",
      target: () => ref3.current,
      prevButtonProps: { children: "Wróć" },
      nextButtonProps: { children: "Dalej" },
    },
    {
      title: "Krok 4 - Aktywuj urządzenie pracownika",
      description:
        "Kliknij, aby odblokować urządzenie pracownikowi.",
      target: () => ref4.current,
      prevButtonProps: { children: "Wróć" },
      nextButtonProps: { children: "Zakończ" },
    },
  ];

  const { id } = useParams();
  const navigate = useNavigate();
  const { pristine, has_error, onFieldsChange } =
    useFormErrors();

  const path = `/workers/${id}`;

  const [color, setColor] = useState(null);

  const bgColor = useMemo(() => color, [color]);

  const { is_loading, is_error, data, refetch } = useGet({
    path,
    query: ["workers", id],
    onSuccess: (res) => setColor(res.data?.color),
  });

  const [form] = Form.useForm();

  const { is_saving, update } = useUpdate({
    path,
    form,
    onSuccess: refetch,
  });

  const { is_deleting, _delete } = useDelete({
    path,
    onSuccess: () => navigate("/workers"),
  });

  if (is_error) {
    return (
      <PageFailure
        title="Nie znaleziono pracownika."
        button_text="Wróć do listy"
        path="/workers"
      />
    );
  }

  return (
    <MainLayout
      back_href="/workers"
      breadcrumbs={[
        { name: "Pracownicy", path: "/workers" },
        { name: data?.id || "Trwa pobieranie..." },
      ]}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} md={24} lg={24} xl={8}>
          <Card
            title="Edytuj pracownika"
            loading={is_loading}
          >
            <Form
              form={form}
              layout="vertical"
              onFieldsChange={onFieldsChange}
              initialValues={{
                first_name: data?.first_name,
                last_name: data?.last_name,
                email: data?.email,
                phone: data?.phone,
                is_active: data?.is_active,
                color: data?.color,
              }}
              onFinish={(values) => update(values)}
            >
              <Form.Item
                label="Imię"
                name="first_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Imię jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedowolone znaki!",
                  },
                ]}
              >
                <Input placeholder="Imię" maxLength={60} />
              </Form.Item>
              <Form.Item
                label="Nazwisko"
                name="last_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Nazwisko jest wymagane!",
                  },
                  {
                    pattern: name_regexp,
                    message:
                      "Pole zawiera niedowolone znaki!",
                  },
                ]}
              >
                <Input
                  placeholder="Nazwisko"
                  maxLength={60}
                />
              </Form.Item>
              <Form.Item
                label="E-mail"
                name="email"
                hasFeedback
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "E-mail jest niepoprawny!",
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                required
                hasFeedback
                label="Telefon"
                name="phone"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu wymagany!"
                          )
                        );
                      }
                      if (value.includes("_")) {
                        return Promise.reject(
                          new Error(
                            "Numer telefonu niepoprawny!"
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <MaskedInput
                  mask="000000000"
                  placeholder="Telefon"
                />
              </Form.Item>
              <Form.Item
                valuePropName="checked"
                name="is_active"
              >
                <Switch
                  ref={ref1}
                  checkedChildren="Aktywny"
                  unCheckedChildren="Nieaktywny"
                  defaultChecked={data?.is_active}
                  onChange={(value) =>
                    form.setFieldValue("is_active", value)
                  }
                />
              </Form.Item>
              <Form.Item name="color" label="Wybierz kolor">
                <ColorPicker
                  value={data?.color}
                  onChange={(value) =>
                    setColor(value.toHexString())
                  }
                  onChangeComplete={(value) =>
                    form.setFieldValue(
                      "color",
                      value.toHexString()
                    )
                  }
                >
                  <Avatar
                    style={{
                      cursor: "pointer",
                      backgroundColor: bgColor,
                    }}
                  >
                    {data?.initials}
                  </Avatar>
                </ColorPicker>
              </Form.Item>
              <Row justify="end">
                <Space>
                  <Button
                    type="primary"
                    disabled={
                      data?.worker_devices?.length > 1
                    }
                    onClick={() => setOpenTour(true)}
                  >
                    Rozpocznij aktywację
                  </Button>
                  <ModalDelete
                    loading={is_deleting}
                    text="pracownika"
                    handleDelete={_delete}
                  />
                  <Button
                    disabled={pristine || has_error}
                    loading={
                      is_deleting || is_loading || is_saving
                    }
                    type="primary"
                    htmlType="submit"
                    ref={ref2}
                  >
                    Zapisz
                  </Button>
                </Space>
              </Row>
            </Form>
          </Card>
          <WorkerPasswordResets
            data={data?.worker_password_resets}
            refetch={refetch}
          />
        </Col>
        <Col xs={24} md={24} lg={24} xl={16}>
          <WorkerDevices
            data={data?.worker_devices}
            refetch={refetch}
            refs={[ref3, ref4]}
          />
          <Card
            title="Logi pracownika"
            loading={is_loading}
            bodyStyle={{
              maxHeight: 825,
              overflowY: "auto",
            }}
          >
            <Logs data={data?.logs || []} />
          </Card>
        </Col>
      </Row>
      <Tour
        open={open_tour}
        onClose={() => setOpenTour(false)}
        steps={steps}
      />
    </MainLayout>
  );
};

export default WorkerUpdate;
